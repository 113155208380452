import { Box } from "@mui/material"
import { Subscription } from "./Subscription"





export const SubscriptionList = () => {
  return (
    <>
      <Box>
        <Subscription />
      </Box>
    </>


  )
}