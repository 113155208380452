// export const BASE_URL = "http://13.50.236.73:8000/api/v1";
// export const BASE_URL_V2 = "http://13.50.236.73:8000/api/v2.0";
export const BASE_URL = 'https://api-uat.onecommunn.com/api/v1';
export const BASE_URL_V2 = 'https://api-uat.onecommunn.com/api/v2.0';
// export const BASE_URL = 'http://localhost:5002/api/v1';
// export const BASE_URL_V2 = 'http://localhost:5002/api/v2.0';
export const ADMIN_APP_BASE_URL = 'http://localhost:3000';
// export const ADMIN_APP_BASE_URL = "http://localhost:3001";
export const USER_APP_BASE_URL = 'https://member.onecommunn.com';
//  export const ADMIN_APP_BASE_URL2 = "http://localhost:3000";
// export const USER_APP_BASE_URL = "http://localhost:3001";
export const USER_APP = "https://member.onecommunn.com";
// export const USER_APP = 'http://192.168.0.106:3001';
export const AI_URL = 'http://16.171.174.90/suggestions';
export const AI_URL_V2 = 'http://16.171.174.90/suggestions/v2';
// export const AI_URL = "http://16.171.174.90/suggestions";
// export const BASE_URL = "https://api.onecommunn.com/api/v1";
// export const BASE_URL_V2 = "https://api.onecommunn.com/api/v2.0";
