import { Box, CircularProgress, Grid, Stack, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { usePlans } from "../../hooks/usePlans";
import Loader from "../Loader/Loader";
import { useSelector } from "react-redux";
import { NoPlans } from "../AlternatePages/noPlans";
import UserPlan, { IPlan } from "./UserPlan";
import AddIcon from "@mui/icons-material/Add";
import { useState } from "react";
import CustomPayModel from "../Payments/CustomPayPopUp";
import { usePayment } from "../../hooks/usePayment";
import { Helmet } from "react-helmet";
import { IPaymentList } from "../../models/payment.model";
import React from "react";
import { useNavigate } from "react-router-dom";
import PaymentSuccess from "../Payments/PaymentSuccessPopUp";
import PaymentFailed from "../Payments/PaymentFailedPopUp";
import { metaConfig } from "../../utils/constants";

export const PlansList = () => {
  const { isLoading, plans } = usePlans();
  // const { id } = useParams();
  // console.log(id, "id");
  const selectedCommunity = useSelector(
    (state: any) => state?.selectedCommunity
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const selectedCommunityId = selectedCommunity?.selectedCommunity?._id;
  const _id = selectedCommunity?.selectedCommunity?._id;
  /////paymentsList api call////////
  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getCommunityPaymentsById,
    initiatePaymentByIds,
    getPaymentStatusById,
    CustomPay,
  } = usePayment();

  const [transaction, setTransaction] = useState<IPaymentList>();
  const [customloading, setCustomLoading] = React.useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [filteredPaymentsList, setFilteredPaymentsList] = useState<
    IPaymentList[]
  >([]);
  const [reson, setReson] = React.useState("");
  const [amount, setAmount] = React.useState<any>("");

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [retryLoadingStates, setRetryLoadingStates] = useState<boolean[]>(
    Array(filteredPaymentsList?.length).fill(false)
  );
  /////

  ///////Reinitiate Payment Api///////

  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [community, setCommunity] = useState("");

  // const [communityId, setCommunityId] = useState<ICommunity[]>([]);

  // console.log(_id, "communityId");

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [plan, setplan] = useState("");
  enum PaymentStatus {
    SUCCESS = "SUCCESS",
    PENDING = "PENDING",
    FAILED = "FAILED",
    USERCANCELLED = "USERCANCELLED",
  }
  // payment response function
  // const paymentResponse = (response: any) => {
  //   const tnxId = response?.transactionId;
  //   const transaction = response?.transaction as IPaymentList;
  //   if (transaction) setTransaction(transaction);

  //   const screenWidth = window.screen.width;
  //   const screenHeight = window.screen.height;
  //   const width = Math.min(1000, screenWidth);
  //   const height = Math.min(1000, screenHeight);
  //   const left = (screenWidth - width) / 2;
  //   const top = (screenHeight - height) / 2;

  //   // Log user agent for debugging
  //   // console.log("User Agent:", navigator.userAgent);

  //   // Check if iOS device
  //   const isIOSDevice = /iPhone|iPad|iPod/i.test(navigator.userAgent);

  //   const isWebView = !!(window as any).webkit?.messageHandlers;
  //   // console.log("window.webkit:", isWebView ? "Available" : "Undefined");

  //   if (isIOSDevice && isWebView) {
  //     // Running inside an iOS WebView, use webkit.messageHandlers for communication
  //     // console.log("Running inside WebView, using webkit.messageHandlers");

  //     const message = {
  //       action: "openNewWindow",
  //       data: JSON.stringify({
  //         openUrl: response.url,
  //         // closeWindow: [
  //         //   { regex: "(^https://testpay\\\\.easebuzz\\\\.in/error)" },
  //         //   {
  //         //     regex:
  //         //       "(^https://api\\\\.onecommunn\\\\.com/api/v1/payments/failure)",
  //         //   },
  //         //   {
  //         //     regex:
  //         //       "(^https://api\\\\.onecommunn\\\\.com/api/v1/payments/success)",
  //         //   },
  //         // ],
  //       }),
  //     };

  //     (window as any).webkit.messageHandlers.webToNativeInterface.postMessage(
  //       message
  //     );
  //     // console.log("Posted message to webkit:", message);
  //   } else {
  //     // Fallback for non-WebView environments (Safari or other browsers)
  //     // console.log("Opening window in a non-WebView environment");

  //     const windowRef = window.open(
  //       response.url,
  //       "_blank",
  //       `addressbar=no,directories=no,titlebar=no,toolbar=no,location=0,status=no,menubar=no,scrollbars=no,resizable=no, width=${width},height=${height},left=${left},top=${top}`
  //     );

  //     const intervalRef = setInterval(async () => {
  //       const paymentStatus = await getPaymentStatusById(tnxId);
  //       //console.log(paymentStatus, "paymentStatus");
  //       if (paymentStatus && paymentStatus.length > 0) {
  //         clearInterval(intervalRef);
  //         windowRef?.close();
  //         if (paymentStatus[0]?.status === PaymentStatus.SUCCESS) {
  //           setSuccessOpen(true);
  //         } else {
  //           setFailureOpen(true);
  //         }
  //       }
  //     }, 1000);
  //   }
  // };



  const paymentResponse = (response: any) => {
    const tnxId = response?.transactionId;
    const transaction = response?.transaction as IPaymentList;
    if (transaction) setTransaction(transaction);
    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;
    const width = Math.min(1000, screenWidth);
    const height = Math.min(1000, screenHeight);
    const left = (screenWidth - width) / 2;
    const top = (screenHeight - height) / 2;
    const windowRef = window.open(
      response.url,
      // "_self",
      `addressbar=no,directories=no,titlebar=no,toolbar=no,location=0,status=no,menubar=no,scrollbars=no,resizable=no, width=${width},height=${height},left=${left},top=${top}`
    );
    const intervalRef = setInterval(async () => {
      const paymentStatus = await getPaymentStatusById(tnxId);
      if (paymentStatus && paymentStatus.length > 0) {
        clearInterval(intervalRef);
        windowRef?.close();
        if (paymentStatus[0]?.status === PaymentStatus.SUCCESS) {
          setSuccessOpen(true);
        } else {
          setFailureOpen(true);
        }
      }
    }, 1000);
  };


  const handleRetryClick = async (
    communityId: string,
    planId: string,
    i: any
  ) => {
    setCommunity(communityId);
    setplan(planId);
    setRetryLoadingStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[i] = true; // Set loading state for this item to true
      return newStates;
    });
    // console.log(communityId, planId);

    const currentDueDate = "24/09/2025";
    const amount = "100";
    const response = await initiatePaymentByIds(
      communityId,
      planId,
      currentDueDate,
      amount
    );
    setRetryLoadingStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[i] = false; // Set loading state for this item back to false
      return newStates;
    });
    paymentResponse(response);
  };

  // UseEffect for  Success and Failed Model Open
  const [timer, setTimer] = useState(5);

  //////PaymentPopup state and Functions/////
  const [successOpen, setSuccessOpen] = React.useState(false);
  const [customPay, setCustomPay] = React.useState(false);
  const [failureOpen, setFailureOpen] = React.useState(false);
  //const [fullWidth, setFullWidth] = React.useState(true);

  const [readonly, setReadOnly] = useState(false);
  const handleSuccessClose = () => {
    setTimer(5);
    navigate("/plans-lists");
    setSuccessOpen(false);
  };

  const handleFailureClose = () => {
    setTimer(5);
    navigate("/plans-lists");
    setFailureOpen(false);
  };
  const handleCustomPayOpen = () => {
    setCustomPay(true);
  };

  const handleCustomPayClose = () => {
    setCustomPay(false);
    setReson("");
    setAmount("");
    setReadOnly(false);
  };

  const handleAmountChange = (newValue: any) => {
    setAmount(newValue);
  };
  const handleResonChange = (newValue: string) => {
    setReson(newValue);
  };
  const handleSubmit = async () => {
    try {
      const formData = new FormData();
      // const formattedAmount = amount.toString() + ".00";
      // formData.append("amount",amount.toString() + ".00");

      const regex = /\.(\d{2})$/;
      //e.log(amount);

      if (regex.test(amount)) {
        formData.append("amount", amount);
      } else {
        formData.append("amount", amount.toString() + ".00");
      }
      formData.append("description", reson);
      setCustomLoading(true);

      if (_id) {
        const updateResponse = await CustomPay(_id, formData);
        paymentResponse(updateResponse);
        if (updateResponse.status === 200) {
          // setCustomLoading(false);
          // setCustomPay(false);
        } else {
          console.error("Update failed:", updateResponse);
        }
      } else {
        // Handle the case where _id is null or undefined
        console.error("Selected community ID is null or undefined");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setCustomLoading(false);
      setAmount("");
      setReson("");
      setCustomPay(false);
    }
  };

  // console.log(plans, "plans")

  //

  //
  return (
    <>
      <Helmet>
        <title>{metaConfig.title.plans}</title>
        <meta name="description" content={metaConfig.description} />
        <meta name="format-detection" content="telephone=no" />
        <meta name="author" content={metaConfig.description} />
        <meta name="keywords" content={metaConfig.description} />
      </Helmet>

      <Stack
        direction={"column"}
        spacing={1}
        sx={{ pb: { xs: 5, md: 1 }, mb: { xs: 5 } }}
      >
        <Stack
          direction="row"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: "15px", md: "20px" },
              fontWeight: 500,
              color: "#50A1CA",
              fontFamily: "Inter",
              textAlign: "center",
            }}
          >
            Plans
          </Typography>

          <Button
            variant="contained"
            onClick={handleCustomPayOpen}
            sx={{
              textTransform: "capitalize",
              borderRadius: "30px",
              font: "Roboto",
              background:
                "linear-gradient(91.58deg, #50A1CA 9.79%, #3B9B7F 92.96%)",
              boxShadow: "none",
              border: "1px solid #E0E0E0",
              color: "white",
              fontSize: { xs: "14px", md: " 16px" },
              fontWeight: 500,
              display: "flex",
              alignItems: "center", // Centers the icon and text vertically
              ":hover": {
                background:
                  "linear-gradient(91.58deg, #50A1CA 9.79%, #3B9B7F 92.96%)",
                color: "#ffffff",
              },
            }}
          >
            <AddIcon
              sx={{
                background:
                  "linear-gradient(91.58deg, #50A1CA 9.79%, #3B9B7F 92.96%)",
                color: "white",
                borderRadius: "20px",
                fontSize: "20px",
                boxShadow: "none",
                mr: 1, // Reduced margin for better alignment
              }}
            />
            Custom Pay
          </Button>
        </Stack>

        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            {isLoading ? ( // Show loader while loading
              <Loader /> //
            ) : plans.length === 0 ? (
              <NoPlans />
            ) : (
              <Stack direction="column" spacing={1}>
                {plans?.map((plan: IPlan) => (
                  <UserPlan plan={plan} key={plan?._id} plans={plans} />
                ))}
              </Stack>
            )}

            <Box
              sx={{
                display: { xs: "none", md: "flex" },
                justifyContent: "center",
                alignItems: "center",
                pt: 3,
                pb: 2,
                mb: { xs: 3, md: 0 },
              }}
            >
              <Button
                variant="contained"
                onClick={handleCustomPayOpen}
                sx={{
                  textTransform: "capitalize",
                  borderRadius: "30px",
                  font: "Roboto",
                  background:
                    "linear-gradient(91.58deg, #50A1CA 9.79%, #3B9B7F 92.96%)",
                  boxShadow: "none",
                  border: "1px solid #E0E0E0",
                  color: "white",
                  fontSize: "16px",
                  fontWeight: 600,

                  ":hover": {
                    background:
                      "linear-gradient(91.58deg, #50A1CA 9.79%, #3B9B7F 92.96%)",
                    color: "#ffffff",
                  },
                }}
              >
                <AddIcon
                  sx={{
                    background:
                      "linear-gradient(91.58deg, #50A1CA 9.79%, #3B9B7F 92.96%)",
                    color: "white",
                    borderRadius: "20px",
                    fontSize: "20px",
                    boxShadow: "none",
                    mr: 2,
                  }}
                />
                Custom Pay
              </Button>
            </Box>
            <CustomPayModel
              fullWidth={true}
              maxWidth={"xs"}
              customPay={customPay}
              handleCustomPayClose={handleCustomPayClose}
              buttonText={
                customloading ? (
                  <CircularProgress size={24} sx={{ color: "white" }} />
                ) : (
                  "Submit"
                )
              }
              handleCustomPayIcon={handleCustomPayClose}
              handleSubmitClick={() => {
                //if (amount && amount > 99.0) {
                handleSubmit();
                //}
              }}
              paymentvalue={amount || ""}
              paymentOnChange={handleAmountChange}
              paymenReason={reson}
              paymentReasonOnChange={handleResonChange}
              readonly={readonly}
            />
            {/* Payment Success Pop up */}
            <PaymentSuccess
              txnid={transaction?.txnid || ""}
              open={successOpen}
              amount={transaction?.amount || ""}
              timer={timer}
              dialogText={""}
              onClose={handleSuccessClose}
              onClickNavigateButton={() => {
                navigate("/payments");
                setSuccessOpen(false);
              }}
            />

            {/* Payment Failed Pop up */}
            <PaymentFailed
              open={failureOpen}
              onClose={handleFailureClose}
              amount={transaction?.amount || ""}
              txnid={transaction?.txnid || ""}
              onClickNavigateButton={() => {
                navigate("/payments");
                setFailureOpen(false);
              }}
              dialogText={""}
              timer={timer}
              OnClickRetryButtonFunction={() => {
                if ((transaction?.udf2 as any) === "65cf774d1c183d386c3d3911") {
                  setFailureOpen(false);
                  setCustomPay(true);
                  setAmount(transaction?.amount);
                  setReson(transaction?.udf4 || "");
                } else {
                  handleRetryClick(
                    transaction?.udf3?._id || "",
                    transaction?.udf2?._id || "",
                    ""
                  );
                }
              }}
            />
          </Grid>
        </Grid>
      </Stack>
    </>
  );
};
