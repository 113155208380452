import {
  Avatar,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import { metaConfig } from '../../utils/constants';
import { Helmet } from 'react-helmet';
import { useEffect, useState } from 'react';
import { INotification } from '../../models/notifications.model';
import store from '../../store';
import { useSelector } from 'react-redux';
import { NotificationList } from '../../hooks/useNotification';
import { NoPlans } from '../AlternatePages/noPlans';
import { AlternativePage } from '../AlternatePages/AlternativePage';

const Notifications = () => {
  const [notifications, setNotifications] = useState<INotification[]>([]);
  const [loadNotifications, setLoadNotifications] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const { getNotificationList, updateNotificationStatus } = NotificationList();

  const state = store.getState();
  const loggedInUserData = useSelector(() => {
    return state?.loggedInUser || null;
  });

  const selectedCommunity = useSelector(
    (state: any) => state.selectedCommunity.selectedCommunity
  );
  const userID = loggedInUserData?.user?.id || undefined;

  // Retrieve communityId from localStorage
  const storedCommunityId = localStorage.getItem('communityId');

  const loadNotifcations = async () => {
    setLoadNotifications(true);
    try {
      const response = await getNotificationList(userID);
      if (response && response.data) {
        const notificationData = response.data.notifications;

        // Filter notifications for the selected community
        const filteredNotifications = notificationData.filter(
          (notification) => notification?.communityId === storedCommunityId
        );
        setNotifications(filteredNotifications);
        const sentCount = filteredNotifications.filter(
          (notification) => notification.status === 'SENT'
        ).length;
        console.log(`Number of notifications with status SENT: ${sentCount}`);
      } else {
        console.log('No notifications found.');
      }
    } catch (err) {
      console.log('Error: ', err);
    } finally {
      setLoadNotifications(false);
    }
  };

  const handleNotificationClick = async (notificationId: string) => {
    try {
      setIsUpdating(true);
      const success = await updateNotificationStatus([notificationId], 'READ');
      if (success) {
        setNotifications((prevNotifications) =>
          prevNotifications.map((notification) =>
            notification._id === notificationId
              ? { ...notification, status: 'READ' }
              : notification
          )
        );
      }
    } catch (error) {
      console.log('Error updating notification status:', error);
    } finally {
      setIsUpdating(false);
    }
  };

  useEffect(() => {
    loadNotifcations();
  }, []);

  return (
    <>
      <Helmet>
        <title>{metaConfig.title.notification}</title>
        <meta name='description' content={metaConfig.description} />
        <meta name='format-detection' content='telephone=no' />
        <meta name='author' content={metaConfig.description} />
        <meta name='keywords' content={metaConfig.description} />
      </Helmet>

      <Stack
        direction='row'
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: 2,
        }}
      >
        <Typography
          sx={{
            fontSize: { xs: '15px', md: '20px' },
            fontWeight: 500,
            color: '#50A1CA',
            fontFamily: 'Inter',
            textAlign: 'center',
          }}
        >
          Notifications
        </Typography>
      </Stack>
      {
        notifications?.length === 0 ? (
          <AlternativePage title='No Notifications' image={require("./../../assets/icons/svg-icons/noPlans.png")} />
        ) : (
          <List>
            {notifications.map((item, index) => (
              <ListItemButton
                key={index}
                sx={{
                  boxShadow: 0.5,
                  p: 1,
                  m: 1,
                  backgroundColor: 'white',
                  fontFamily: 'Inter',
                  borderRadius: '5px',
                  border: '1px solid #EAEAEA',
                }}
                onClick={() => handleNotificationClick(item._id)}
              >
                <ListItemAvatar>
                  <Avatar alt='Profile Picture' src={selectedCommunity?.logo} />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography
                      sx={{
                        fontWeight: item.status === 'SENT' ? 'bold' : 'normal',
                      }}
                    >
                      {item.title}
                    </Typography>
                  }
                  secondary={item.body}
                  sx={{
                    fontFamily: 'Inter',
                  }}
                />
              </ListItemButton>
            ))}
          </List>
        )
      }

    </>
  );
};

export default Notifications;
