import Stack from '@mui/material/Stack';
import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { CircularProgress, FormControl, TextField } from '@mui/material';
// import EmojiPicker from "emoji-picker-react";
import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import { usePosts } from '../../hooks/usePosts';
import { ICommunity } from '../../models/communities.modal';
import { useSelector } from 'react-redux';
import { IPost } from '../Posts/Posts';

import { Close } from '@mui/icons-material';
import { useCommunity } from '../../hooks/useCommunity';

interface AddPostProps {
  open: boolean;
  onClose: () => void;
  postHeder: string;
  editPostData?: IPost;
  selectedCommunity: ICommunity[];
  updatePosts: (newPost: IPost) => void;
  updateExistingPost: (updatedPost: IPost) => void;
  placeHolder: string;
}

export const AddPost: React.FC<AddPostProps> = ({
  open,
  onClose,
  postHeder,
  editPostData,
  selectedCommunity,
  updatePosts,
  updateExistingPost,
}) => {
  //a

  const { createNewPost, editPost } = usePosts();

  const selected = useSelector((state: any) => state?.selectedCommunity);
  const community = selected?.selectedCommunity;
  useEffect(() => {
    if (community) {
      setSelectedCommunities([community]);
    }
  }, [community]);

  const [selectedCommunities, setSelectedCommunities] = useState<any[]>(
    community ? [community] : []
  );

  // const [membersCommunities, setMembersCommunities] = useState<any[]>();
  // Use the provided selectedCommunity prop or an empty array

  // const { memberCommunities } = useCommunity();
  // useEffect(() => {
  //   async function fetchData() {
  //     console.log('fetch123');

  //     const responseData = await memberCommunities();

  //     const communities = responseData.map(
  //       (obj: { community: any }) => obj?.community
  //     );

  //     setMembersCommunities(communities);
  //   }

  //   fetchData();
  // }, []);

  const [selectedOption, setSelectedOption] = useState('EVERY_ONE');

  const [richTextContent, setRichTextContent] = useState('');
  const [hashTag, setHashTag] = useState('');

  const [editedPost, setEditedPost] = useState<IPost | undefined>(editPostData);
  const [isEditing, setIsEditing] = useState(false);

  const [loading, setLoading] = useState(false);
  // const Community = useSelector(() => {
  //   return state?.selectedCommunity;
  // });

  // const [selectedCommunityId, setSelectedCommunityId] = useState(
  //   Community.selectedCommunity?.id
  // );

  // console.log(placeHolder,"HERE")

  const [files, setFiles] = useState<FileList | null>(null);

  const handleCreatePost = async () => {
    setLoading(true);
    const formData = new FormData();
    console.log(formData);
    // Check if postHeder is "Add New Post"
    if (postHeder === 'Add New Post' && formData) {
      formData.append('whoCan', selectedOption);
      formData.append('content', richTextContent);
      formData.append('hashTag', hashTag);
      selectedCommunities?.forEach((community) => {
        formData.append('communities', community._id);
      });

      try {
        const response = await createNewPost(formData, files);
        // console.log(response);
        if (response && response.status === 201) {
          setFiles(null);
          onClose();

          updatePosts(response.data.data);
        }
      } catch (error) {
      } finally {
        // Set loading back to false regardless of success or failure
        setLoading(false);
      }
    } else if (isEditing && editedPost) {
      // console.log("edit post is calling");
      const formData = new FormData();
      formData.append('whoCan', selectedOption);
      formData.append('content', richTextContent);
      formData.append('hashTag', hashTag);
      // Append the selected communities to the FormData
      selectedCommunities.forEach((community) => {
        formData.append('communities', community._id);
      });

      try {
        const response = await editPost(editedPost._id, formData, files);

        // console.log(response?.data.data);

        if (response && response.status === 200) {
          setFiles(null);
          setLoading(false);
          onClose();

          updateExistingPost(response?.data?.data);
        }
      } catch (error) { }
    }
  };
  // open Modal Style
  useEffect(
    () => {
      if (postHeder === 'Edit Post' && editPostData) {
        setEditedPost(editPostData);
        setIsEditing(true);
        setSelectedCommunities(editPostData?.communities ?? []);
        setSelectedOption(editPostData.whoCan);
        setRichTextContent(editPostData?.content ?? '');
        setHashTag(editPostData?.hashTag);
      } else {
        // Reset fields for other modes
        setEditedPost(undefined);
        setIsEditing(false);

        setSelectedOption('EVERY_ONE');
        setRichTextContent('');
        setHashTag('');
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [postHeder, open, onclose]
  );

  const handleContentChange = (event: any) => {
    setRichTextContent(event.target.value);
  };

  const handlePostImages = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files.length) {
      setFiles((prevFiles) => {
        const combinedFiles = prevFiles ? [...Array.from(prevFiles)] : [];
        for (let i = 0; i < files.length; i++) {
          combinedFiles.push(files[i]!);
        }
        const dataTransfer = new DataTransfer();
        combinedFiles.forEach((file) => {
          dataTransfer.items.add(file);
        });
        return dataTransfer.files;
      });
    }
  };

  const fileLabelText = files
    ? files.length === 1
      ? files[0].name
      : `${files.length} files selected`
    : 'No file chosen';

  const handleDeleteImage = (index: number) => {
    if (files) {
      const newFiles = Array.from(files);
      newFiles.splice(index, 1);
      setFiles(newFiles.length > 0 ? (newFiles as unknown as FileList) : null);
    }

    // console.log(e.target.files);
  };

  const isDataAvailable =
    selectedCommunities && selectedOption && (files || richTextContent);

  // console.log(selectedCommunities);
  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          onClose();
          // Call the resetState function when the modal is closed
        }}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Stack direction={'column'} spacing={2}>
          <Box
            sx={{
              position: 'absolute' as 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              bgcolor: 'background.paper',
              boxShadow: 24,

              overflow: 'auto',
              maxHeight: '100%',
              width: { xs: '320px', sm: '320px', md: '750px', lg: '750px' },
              borderRadius: "10px",
            }}
          >
            <Stack
              direction={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
              sx={{ backgroundColor: '#F0F9FF', pl: 2, pr: 2, pt: 1, pb: 1 }}
            >
              <Stack>
                <Typography
                  sx={{
                    fontSize: { xs: '15px', md: '20px' },
                    fontWeight: 600,
                    textDecoration: 'none',
                    color: '#50A1CA',
                    fontFamily: 'Inter',
                    textAlign: 'center',
                    textTransform: 'capitalize',
                  }}
                >
                  {postHeder}
                </Typography>
              </Stack>
              <Stack>
                <FormControl sx={{ m: 1, minWidth: 100, margin: '-8px 8px ' }}>
                  {/* <Select
                    inputProps={{ "aria-label": "Without label" }}
                    size="small"
                    startAdornment={
                      <InputAdornment position="start">
                        <VisibilityIcon />
                      </InputAdornment>
                    }
                    value={selectedOption}
                    onChange={handleSelectChange}
                    sx={{ minHeight: 31, fontSize: "12px", fontWeight: 500 }}
                  >
                    {dropdownOptions.map((option) => (
                      <MenuItem key={option.key} value={option.value}>
                        {option.key}
                      </MenuItem>
                    ))}
                  </Select> */}
                </FormControl>
              </Stack>
              <Stack>
                <Box
                  onClick={onClose}
                  sx={{
                    backgroundColor: '#50A1CA',
                    padding: '4px',
                    borderRadius: '50%',
                    width: '20px',
                    height: '20px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: 'white',
                    fontSize: '10px',
                    mt: '0.5px',

                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: '#50A1CA', // Background color on hover
                      cursor: 'pointer',
                      border: 'none',
                    },
                  }}
                >
                  <Close sx={{ fontSize: '15px' }} />
                </Box>
              </Stack>
            </Stack>

            <Stack spacing={2} sx={{ mt: 2, pl: 2, pr: 2, pt: 1, pb: 1 }}>
              {/* <Autocomplete
                sx={{ backgroundColor: 'white', maxHeight: '10vh' }}
                limitTags={4}
                size='small'
                id='community-autocomplete'
                options={[]}
                isOptionEqualToValue={(option, value) =>
                  option?._id === value?._id
                }
                disabled
                getOptionLabel={(option) => option?.title || option?.name}
                value={selectedCommunities}
                onChange={(event, newValue) => setSelectedCommunities(newValue)}
                // onInputChange={(event, newInputValue) => {
                //   // Manually update the selectedCommunities state when input changes
                //   // This ensures that the value reflects the updated state
                //   setSelectedCommunities((prevSelectedCommunities) =>
                //     prevSelectedCommunities.filter((community) =>
                //       community.name
                //         .toLowerCase()
                //         .includes(newInputValue.toLowerCase())
                //     )
                //   );
                // }}
                multiple // Enable multi-select
                // disabled={isReadOnly}
                disableCloseOnSelect // Keep the dropdown open when selecting multiple options
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox style={{ marginRight: 8 }} checked={selected} />
                    {option?.name || option?.title}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label='Choose Communities'
                    placeholder='Choose Communities'
                    sx={{ width: '100%' }}
                  />
                )}
              /> */}

              <TextField
                label="Share your thoughts"
                placeholder="Share your thoughts"
                multiline
                rows={3}
                variant='outlined'
                fullWidth
                value={richTextContent}
                onChange={handleContentChange}
              />
              {/* <TextField
                label="HashTag"
                size="small"
                rows={1}
                variant="outlined"
                fullWidth
                value={hashTag}
                onChange={handleHashTagChange}
                sx={{ fontSize: "11px", font: "Roboto" }}
              /> */}

              <Stack
                display={'flex'}
                justifyContent={'flex-start'}
                alignItems={'center'}
                direction={'row'}
                spacing={1}
                sx={{
                  width: { xs: '100%', md: '50%' },
                  p: 1,
                  borderRadius: '5px',
                  padding: '8px',
                  backgroundColor: '#ffffff',
                  color: '#5E6C84',
                  cursor: 'pointer',
                }}
              >
                <input
                  type='file'
                  name='uploadfile'
                  id='img'
                  multiple
                  accept='image/jpeg, image/png, image/gif'
                  style={{ display: 'none' }}
                  onChange={handlePostImages}
                />
                {postHeder === 'Add New Post' && (
                  <>
                    <label htmlFor='img'>
                      <Typography
                        sx={{
                          fontSize: '12px',
                          border: '2px solid #5E6C84',
                          backgroundColor: '#5E6C84',
                          color: '#ffffff',
                          fontFamily: 'Inter',
                          pl: 2,
                          pr: 2,
                          pt: 0.5,
                          pb: 0.5,
                          cursor: 'pointer',
                          borderRadius: '2px',
                        }}
                      >
                        Choose File
                      </Typography>
                    </label>
                    <Typography sx={{ fontSize: '11px', fontFamily: 'Inter' }}>
                      {fileLabelText}
                    </Typography>
                    {/* <Typography sx={{fontSize:'10px', fontFamily:'Inter', color:'#575757', display:{xs:'none', md:'block'}}}>Size : 1080px X 1080px</Typography> */}
                  </>
                )}
              </Stack>

              <Stack direction={'row'}>
                {files && (
                  <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {files &&
                      Array.from(files)
                        .slice(0, 5)
                        .map((file, index) => (
                          <div
                            key={index}
                            style={{
                              position: 'relative',
                              margin: '5px',
                              width: '100px',
                              height: '100px',
                            }}
                          >
                            <img
                              src={URL.createObjectURL(file)}
                              alt={`Selected Image ${index}`}
                              style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                              }}
                            />
                            <button
                              onClick={() => handleDeleteImage(index)}
                              style={{
                                position: 'absolute',
                                top: '5px',
                                color: 'white',
                                right: '5px',
                                background: 'transparent',
                                border: 'none',
                                cursor: 'pointer',
                                padding: '0',
                                fontSize: '5px',
                                backgroundColor: 'red',
                                borderRadius: '100%',
                              }}
                            >
                              <Close sx={{ fontSize: '15px' }} />
                            </button>
                          </div>
                        ))}
                  </div>
                )}
                {files && files.length > 5 && (
                  <div
                    style={{
                      position: 'relative',
                      margin: '5px',
                      width: '100px',
                      height: '100px',
                    }}
                  >
                    <div
                      style={{
                        position: 'absolute',
                        top: '0',
                        left: '0',
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        color: 'white',
                      }}
                    >
                      +{files.length - 5}
                    </div>
                  </div>
                )}
              </Stack>
            </Stack>

            <Stack direction='row' spacing={1} sx={{ mt: 3, ml: 2 }}>
              {editPostData?.images.map((image, index) => (
                <div
                  key={index}
                  style={{ position: 'relative', textAlign: 'center' }}
                >
                  <img
                    src={image} // Assuming the image URLs are stored in the `images` property of `editedPost`
                    alt={`item ${index}`}
                    style={{
                      maxWidth: '140px',
                      maxHeight: '200px',
                      objectFit: 'cover',
                    }}
                  />
                </div>
              ))}
            </Stack>

            <Stack spacing={1}>
              <Stack direction={'row'} spacing={2}></Stack>

              <Stack
                direction={'row'}
                sx={{ pl: 2, pr: 2, pt: 1, pb: 1 }}
                spacing={5}
              >
                <Button
                  variant='contained'
                  color='primary'
                  size='medium'
                  disabled={!isDataAvailable || loading}
                  onClick={handleCreatePost}
                  sx={{
                    px: 5,
                    pl: 2,
                    pr: 2,
                    pt: 1,
                    pb: 1,
                    textTransform: 'capitalize',
                    borderRadius: '8px',
                    width: '100px',
                    background:
                      'linear-gradient(91.58deg, #50A1CA 9.79%, #3B9B7F 92.96%)',
                  }}
                >
                  {loading ? (
                    <CircularProgress size={24} sx={{ color: 'white' }} />
                  ) : postHeder === 'Edit Post' ? (
                    'Update'
                  ) : (
                    'Post'
                  )}
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Modal>
    </>
  );
};
